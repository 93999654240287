import { LogoMain } from "@havan/paladinos-react-icons";
import { FormLogin } from "./login-form";
import styles from "./hero.module.css";

export function Hero() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src="/images/banner/liberty-04-2025.webp" alt="Liberdade" />
          <div className={styles.description}>
            <img
              src="/images/banner/corte-divida-04-2025.svg"
              alt="Um corte certeiro na sua divida"
            />

            <div className={styles.seals}>
              <div className={styles.debt}>
                <img
                  src="/images/banner/liquida-04-2025.webp"
                  alt="Liquida divida"
                />
                <LogoMain />
              </div>
              <img
                src="/images/banner/seal-04-2025.svg"
                alt="ate 95% desconto"
              />
            </div>
            <p>*Oferta disponível conforme a condição do credor</p>
          </div>
        </div>
        <FormLogin />
      </div>
    </section>
  );
}
